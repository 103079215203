
import { createRouter, createWebHashHistory } from 'vue-router'


import becomeMember from '@/pages/becomeMember/index.vue'

import becomePartner from '@/pages/becomePartner/index.vue'
import becomePromoter from '@/pages/becomePromoter/index.vue'

import indexPage from '@/pages/index/index.vue'

import openOther from '@/pages/openOther/index.vue'


//第二步 : 创建路由器
const router = createRouter({
    history: createWebHashHistory(), //路由器的工作模式
    routes: [ //一个个的路由规则
        {
            path: '/',
            component: indexPage,
            meta: {
                title: '深读行'
            }
        },
        {
            name: 'openOther',
            path: '/openOther',
            component: openOther,
            meta: {
                title: '进入中......'
            }

        },
        {
            path: '/becomeMember',
            component: becomeMember,
            meta: {
                title: '成为会员'
            }
        },
        {
            path: '/becomePartner',
            component: becomePartner,
            meta: {
                title: '共建分会'
            }
        },
        {
            path: '/becomePromoter',
            component: becomePromoter,
            meta: {
                title: '成为阅读推广大使'
            }
        }
    ]
})


//router设置页面标题
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

//暴露出去
export default router
