<template>
    <div class="container">
        <div class="header">
            <img src="@/statics/image/promote-cover1.jpg" class="promo-image">
        </div>

        <div class="content">
            <h3 class="section-title">成为阅读推广大使</h3>

            <p class="paragraph">
                亲爱的读友们，感谢你一直以来的支持与陪伴！为了回馈大家的厚爱，我们特别推出了"分享有礼"活动，诚邀您与亲朋好友共享这份喜悦，一同解锁专属好礼！
            </p>

            <div class="activity-rules paragraph">
                <h4 class="subtitle">🔥【活动规则超简单】🔥</h4>
                <ol>
                    <li>1. 一键分享：只需动动手指，将我们的小程序分享至您的微信朋友圈，微信好友，鼓励朋友们通过您的分享链接进行登录注册深读行·读书会小程序，可得到红包奖励。</li>
                    <li>2. 查看奖励：点击推广数据，查看个人邀请的好友明细和查看佣金奖励明细，佣金可直接提现银行卡。</li>
                </ol>
            </div>
        </div>

        <div class="image-section">
            <img src="@/statics/image/promote-cover2.jpg" alt="阅读推广形象" class="promo-image">
        </div>

        <div class="reasons-section">
            <h3 class="section-title">为什么选择成为推广大使？</h3>
            <ul class="reasons-list">
                <li>
                    <h4 class="reason-title">传递价值</h4>
                    <p>分享好书，让知识的种子在更多人心中生根发芽。</p>
                </li>
                <li>
                    <h4 class="reason-title">社群力量</h4>
                    <p>汇聚爱书之人，共同构建积极向上的阅读社群。</p>
                </li>
                <li>
                    <h4 class="reason-title">专属福利</h4>
                    <p>享受现金红包的推广成效的丰厚奖励！</p>
                </li>
            </ul>
        </div>


        <div class="fix-bottom">
            <button class="join-btn" @click="handleJoinPromote">
                邀请好友 一起深读行
            </button>
        </div>
    </div>
</template>

<script setup>








const handleJoinPromote = () => {
    wx.miniProgram.navigateTo({ url: "/pagesA/pages/invatition/invatition" })
}
</script>

<style lang="less" scoped>
.container {
    background-color: #FFFFFF;

    padding-bottom: 200px;



    .fix-bottom {
        width: 100vw;
        height: 82px;
        background: #FFFFFF;
        box-shadow: 3px 2 3px 1px rgba(0, 0, 0, 0.16);
        border: 1px solid #FFFFFF;

        position: fixed;
        bottom: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;


        .join-btn {
            width: 193px;
            height: 42px;
            background: #7494FF;
            border-radius: 20px;

            border: none;

            font-size: 16px;
            color: #FFFFFF;
        }
    }

}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.03em;
}

.subtitle {
    font-size: 14px;
    color: #585858;
    margin-top: 10px;

}

.content {
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    align-items: center;


}

.section-title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.03em;
    margin-bottom: 25px;
}

.paragraph {
    font-weight: 400;
    font-size: 14px;
    color: #585858;
    line-height: 26px;
    letter-spacing: 0.08em;
}

.activity-rules {
    margin-top: 20px;

    ol {
        li {
            font-weight: 400;
            font-size: 14px;
            color: #585858;
            line-height: 26px;
            letter-spacing: 0.08em;
            margin-bottom: 10px;
        }
    }


}

.image-section {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0 16px;


    .promo-image {
        width: 100%;
    }
}

.reasons-section {
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    align-items: center;


    .section-title {
        margin-bottom: 26px;

    }
}



.reasons-list {
    list-style-type: disc;
    counter-reset: item;
    padding: 0;
    color: #D0D0D0;
    /* 将颜色改为你想要的颜色 */


    li {
        margin-bottom: 15px;
    }

    .reason-title {
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        margin-bottom: 5px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        color: #585858;
        line-height: 26px;
        letter-spacing: 0.08em;
        padding-bottom: 10px;

        border-bottom: 1px solid #EAEAEA;
    }
}
</style>